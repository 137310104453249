














































































import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/header.vue"; // @ is an alias to /src
import SidebarReports from "@/components/sidebar-reports.vue";
import HRMService from "../../../services/hrm-service";

@Component({
  components: {
    Header,
    SidebarReports,
  },
})
export default class BalanceSheet extends Vue {
  private data: any[] = [];
  public filter = null;
  public filterOn = [];

  public currentItem = null;
  public currentIndex = -1;
  public currentPage = 1;
  public title = "";
  trans = [];
  public fields = [
    {
      key: "account",
    },
    {
      key: "description",
    },
    {
      key: "balance",
    },
    {
      key: "total",
    },
  ];
  get rows() {
    return this.selected_items.length;
  }

  public items = [];
  public selected_items = [
    {
      id: 1,
      account: "9090903 - ALI AL-BUSAILI",
      description: "...",
      balance: 190322,
      total: 8000320,
    },
    {
      id: 2,
      account: "9090904 - ALI AL-BUSAILI",
      description: "...",
      balance: 1923322,
      total: 8000320,
    },
    {
      id: 3,
      account: "9090905 - USAMA AL-BUSAILI",
      description: "...",
      balance: 130322,
      total: 0,
    },
    {
      id: 4,
      account: "9090906 - FARHAN AL-BUSAILI",
      description: "...",
      balance: 43322,
      total: 0,
    },
    {
      id: 5,
      account: "9090907 - OWAIS AL-BUSAILI",
      description: "...",
      balance: 190322,
      total: 0,
    },
    {
      id: 6,
      account: "9090908 - KASHIF AL-BUSAILI",
      description: "...",
      balance: 134322,
      total: 0,
    },
  ];
  retrieve() {
    // HRMService.getAll()
    //   .then((response) => {
    //     this.items = response.data;
    //     this.selected_items = this.items;
    //     console.log(this.items)
    //   })
    //   .catch((e) => {
    //     console.log(e);
    //   });
  }

  // refreshList() {
  //   this.retrieve();
  //   this.currentItem = null;
  //   this.currentIndex = -1;
  // }

  // setActiveItem(data: any, index: number) {
  //   this.currentItem = data;
  //   this.currentIndex = index;
  // }

  // removeAll() {
  //   HrmService.deleteAll()
  //     .then((response) => {
  //       console.log(response.data);
  //       this.refreshList();
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // }

  // searchTitle() {
  //   HRMService.findByTitle(this.title)
  //     .then((response) => {
  //       this.data = response.data;
  //       console.log(response.data);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // }

  // mounted() {
  //   this.retrieve();
  // }
}
